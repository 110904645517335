@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
